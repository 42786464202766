import {useEffect, useState} from "react";
import {Link, useParams, useNavigate} from 'react-router-dom'

import axios from "axios";
import {useKeycloak} from "@react-keycloak/web";
import {useLocation} from "react-router-dom";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Select, MenuItem } from '@mui/material';
import MLModel from "../components/SmartMetersInspection/MLModel";
import SmartMeter from "../components/SmartMetersInspection/SmartMeter";
import Data from "../components/SmartMetersInspection/Data";
import AlertCustom from "../components/layout/AlertCustom";
import Breadcrumb from "../components/layout/Breadcrumb";
import { ErrorOutlineOutlined } from "@mui/icons-material";

const SmartMetersInspection = () => {
    const {id} = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const {keycloak, initialized} = useKeycloak();

    let breadcrumbs = []

    useEffect(() => {
        let roles = keycloak.realmAccess?.roles
        if (initialized && !(roles.includes('Energy Expert') || roles.includes('inergy_admin'))) {
            navigate('/')
        }
    }, [initialized])

    if (window.location.pathname.replace(/^\/([^\/]*).*$/, '$1') === 'assignments') {
        breadcrumbs = [
            <Link className={'breadcrumbLink'} key="1" to="/">
                {'Homepage'}
            </Link>,
            <Link className={'breadcrumbLink'} key="1" to="/assignments">
                {'Assignments'}
            </Link>,
            <Typography
                key={2}
                color="secondary"
                fontSize={'20px'}
                fontWeight={600}>
                {`Assignment #${id}`}
            </Typography>,];
    }

    if (window.location.pathname.replace(/^\/([^\/]*).*$/, '$1') === 'smart-meters') {
        breadcrumbs = [
            <Link className={'breadcrumbLink'} key="1" to="/">
                {'Homepage'}
            </Link>,
            <Link className={'breadcrumbLink'} key="1" to="/smart-meters">
                {'Smart Meters'}
            </Link>,
            <Typography
                key={2}
                color="secondary"
                fontSize={'20px'}
                fontWeight={600}>
                {`Smart Meter #${id}`}
            </Typography>,];
    }

    const [smartMeterDetails, setSmartMeterDetails] = useState([])
    const [selectedAssignment, setSelectedAssignment] = useState(null)

    // Variables for modal and for edit message snackbars
    const [editModal, setEditModal] = useState(false)
    const [editSuccess, setEditSuccess] = useState(false)
    const [editFailure, setEditFailure] = useState(false)

    // TODO ml_model_id to be deleted in next steps
    useEffect(() => {
        if (window.location.pathname.replace(/^\/([^\/]*).*$/, '$1') === 'smart-meters') {
            axios.get(`/assignments/meter/${id}`)
                .then(response => {
                    setSmartMeterDetails(response.data)
                    setSelectedAssignment(response.data[0])
                })
                .catch(error => {
                    console.log(error)
                })
        }

        if (window.location.pathname.replace(/^\/([^\/]*).*$/, '$1') === 'assignments') {
            axios.get(`/assignments/${id}`)
                .then(response => {
                    setSmartMeterDetails([response.data])
                    setSelectedAssignment(response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [])

    const handleSaveRecommendation = (name, description, details) => {
        const payload = {
            recommendation: {
                name, description, details
            }
        }

        axios.put(`/assignments/edit/${selectedAssignment.id}`, payload)
            .then(response => {
                setEditSuccess(true)
                setSmartMeterDetails(prevSmartMeterDetails =>
                    prevSmartMeterDetails.map(assignment => assignment.id === response.data.id ? response.data : assignment)
                )
                setSelectedAssignment(response.data)
                setEditModal(false)
            })
            .catch(() => {
                setEditFailure(true)
                setEditModal(false)
            })
    }

    const handleCloseSnackbarEdit = () => {
        setEditSuccess(false)
        setEditFailure(false)
    }

    const handleDateChange = (event) => {
        setSelectedAssignment(smartMeterDetails.find(assignment => assignment.forecast_datetime == event.target.value))
    }

    const formatDate = dateString => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            timeZone: "Europe/Athens"
        });
    };

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} welcome_msg={''} />
            <Container maxWidth={false} sx={{ my: 5 }}>
                {selectedAssignment &&
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15}}>
                        <Typography textAlign='center' variant="h6">Forecast Date</Typography>
                        <Select sx={{ ml: 5, paddingX: 2}} value={selectedAssignment.forecast_datetime} onChange={handleDateChange} displayEmpty>
                            <MenuItem value="" disabled>Select a date</MenuItem>
                            {smartMeterDetails.map((assignment, index) => (
                                <MenuItem key={index} value={assignment.forecast_datetime}>{formatDate(assignment.forecast_datetime)}</MenuItem>
                            ))}
                        </Select>
                    </div>
                }
                {initialized &&
                    selectedAssignment ?
                    <>
                        <MLModel model={selectedAssignment.ml_model} formatDate={formatDate}/>
                        <SmartMeter meter={selectedAssignment.meter} />

                        <Data cluster={selectedAssignment} handleSaveRecommendation={handleSaveRecommendation}
                            editModal={editModal}
                            setEditModal={setEditModal} />
                    </>
                    :
                    <div style={{ display: 'flex', alignItems: 'center', margin: 20 }}>
                        <ErrorOutlineOutlined sx={{ marginRight: '8px' }} />
                        <Typography variant="body1">Assignment not available for this smart meter</Typography>
                    </div>
                }
            </Container>

            {editSuccess &&
                <AlertCustom open={editSuccess} actionClose={handleCloseSnackbarEdit} severity={'success'}
                             message={'The recommendation has been successfully edited!'}/>}

            {editFailure &&
                <AlertCustom open={editFailure} actionClose={handleCloseSnackbarEdit} severity={'error'}
                             message={'Oops! Something wrong happened. Please try again!'}/>}

        </>
    );
}

export default SmartMetersInspection;