import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from "@mui/material/TableRow";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TablePagination from '@mui/material/TablePagination';
import Stack from '@mui/material/Stack';

import TroubleshootIcon from "@mui/icons-material/Troubleshoot";

import Breadcrumb from "../components/layout/Breadcrumb";
import Loading from "../components/layout/Loading";
import { StyledTableRow } from "../components/layout/TableComponents";
import { StyledTableCell } from "../components/layout/TableComponents";

const Assignments = () => {
    const navigate = useNavigate()
    const { keycloak, initialized } = useKeycloak();

    const breadcrumbs = [
        <Link className={'breadcrumbLink'} key="1" to="/">
            {'Homepage'}
        </Link>,
        <Typography
            key={2}
            color="secondary"
            fontSize={'20px'}
            fontWeight={600}>
            {'Assignments'}
        </Typography>,
    ];

    const [assignments, setAssignments] = useState([])
    const [assignmentsError, setAssignmentsError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        let roles = keycloak.realmAccess?.roles
        if (initialized && !(roles.includes('Energy Expert') || roles.includes('inergy_admin'))) {
            navigate('/')
        }
    }, [initialized])

    useEffect(() => {
        setLoading(true)
        axios.get('/assignments')
            .then(response => {
                console.log(response.data)
                setAssignments(response.data)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                setAssignmentsError(true)
                console.log(error)
            })
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const sortedAssignments = assignments
        .sort((a, b) => new Date(b.forecast_datetime) - new Date(a.forecast_datetime)); // Sorting by date in descending order

    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const paginatedAssignments = sortedAssignments.slice(startIndex, endIndex);

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} welcome_msg={''} />
            {initialized && <Box sx={{ padding: 3, maxWidth: "100vw" }}>
                <Container maxWidth={false} sx={{ my: 5, display: 'flex' }}>
                    <Container maxWidth={false}>
                        {loading && <Loading />}
                        {assignmentsError &&
                            <Alert severity="error">Could not load assignments. Please try again later.</Alert>}
                        {!loading && !assignmentsError &&
                            <>
                                <Stack spacing={2} sx={{ mt: 2 }}>
                                    <TablePagination
                                        component="div"
                                        count={sortedAssignments.length}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Stack>
                                <Table size="small" aria-label="customized table" sx={{ border: '1px solid #ccc' }}>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>
                                                <Typography fontWeight={'bold'} variant={'subtitle1'}>
                                                    Date (dd/mm/yyyy)
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                <Typography fontWeight={'bold'} variant={'subtitle1'}>
                                                    Device ID
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                <Typography fontWeight={'bold'} variant={'subtitle1'}>
                                                    Device Type
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                <Typography fontWeight={'bold'} variant={'subtitle1'}>
                                                    Cluster
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                <Typography fontWeight={'bold'} variant={'subtitle1'}>
                                                    Cluster assigned description
                                                </Typography>
                                            </StyledTableCell>
                                            <StyledTableCell align="right">{void (0)}</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {paginatedAssignments.map(assignment => (
                                            <StyledTableRow key={assignment.id}>
                                                <StyledTableCell component="th" scope="row">
                                                    <Typography
                                                        variant={'body1'}>{new Date(assignment.forecast_datetime).toLocaleDateString("en-GB", {
                                                        day: "2-digit",
                                                        month: "2-digit",
                                                        year: "numeric",
                                                        timeZone: "Europe/Athens"
                                                    })}</Typography>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <Typography variant={'body1'}>{assignment.meter.device_id}</Typography>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <Typography variant={'body1'}>{assignment.meter.type}</Typography>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <Typography variant={'body1'}>{assignment.assigned_cluster}</Typography>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <Typography
                                                        variant={'body1'}>{assignment.assigned_cluster_profile.short_description}</Typography>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <Typography variant={'body1'} align={'center'}>
                                                        <Button size={'medium'} variant="contained" color={'warning'}
                                                                startIcon={<TroubleshootIcon />}
                                                                onClick={() => navigate(`/assignments/${assignment.id}/inspect`)}
                                                        >
                                                            Details
                                                        </Button>
                                                    </Typography>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <Stack spacing={2} sx={{ mt: 2 }}>
                                    <TablePagination
                                        component="div"
                                        count={sortedAssignments.length}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Stack>
                            </>}
                    </Container>
                </Container>
            </Box>}
        </>
    );
}

export default Assignments;
