import { Link, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { useKeycloak } from "@react-keycloak/web";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

import Breadcrumb from "../components/layout/Breadcrumb";
import { useEffect, useState } from "react";
import Loading from "../components/layout/Loading";
import axios from "axios";
import { MenuItem, Select } from "@mui/material";
import { ErrorOutlineOutlined } from "@mui/icons-material";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const DailyTip = () => {
    const theme = useTheme()
    const { keycloak, initialized } = useKeycloak();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [assignments, setAssignments] = useState([])
    const [selectedAssignment, setSelectedAssignment] = useState(null)

    const breadcrumbs = [
        <Link className={'breadcrumbLink'} key="1" to="/">
            {'Homepage'}
        </Link>,

        <Typography
            key={2}
            color="secondary"
            fontSize={'20px'}
            fontWeight={600}>
            {`Daily Tip`}
        </Typography>,];

    useEffect(() => {
        let roles = keycloak.realmAccess?.roles
        if (initialized && roles.includes('Energy Expert')) {
            navigate('/')
        }
    }, [initialized])

    useEffect(() => {
        setLoading(true)
        axios.get('/assignments/daily')
            .then(response => {
                console.log(response.data)
                setAssignments(response.data)
                response?.data.length > 0 && setSelectedAssignment(response.data[0])
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                console.log(error)
            })
    }, [])

    const handleDateChange = (event) => {
        setSelectedAssignment(assignments.find(assignment => assignment.forecast_datetime == event.target.value))
    }

    const formatDate = dateString => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            timeZone: "Europe/Athens"
        });
    };

    return (
        <>
            {initialized && <>
                <Breadcrumb breadcrumbs={breadcrumbs} welcome_msg={''} />
                {loading && <Loading />}
                {selectedAssignment ?
                    <>
                        <Container maxWidth={false} sx={{ mt: 5 }} data-testid={'dailyTipMySmartMeter'}>
                            {assignments &&
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
                                    <Typography textAlign='center' variant="h6">Available Tips</Typography>
                                    <Select sx={{ ml: 5, paddingX: 2 }} value={selectedAssignment?.forecast_datetime} onChange={handleDateChange} displayEmpty>
                                        <MenuItem value="" disabled>Select a date</MenuItem>
                                        {assignments.map((assignment, index) => (
                                            <MenuItem key={index} value={assignment.forecast_datetime}>{formatDate(assignment.forecast_datetime)}</MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            }

                            <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
                                <Typography variant={'h5'} sx={{ color: theme.palette.primary.main, fontWeight: 500 }}>My Smart
                                    Meter</Typography>

                                <Grid container spacing={2} justifyContent={'flex-start'} alignItems={'center'} mt={4}>
                                    <Grid item xs={6} md={2}>
                                        <Box display={'flex'} flexDirection={'column'}>
                                            <Typography variant={'h6'} align={'center'}>
                                                Device ID
                                            </Typography>
                                            <Typography variant={'body1'} align={'center'}>
                                                {selectedAssignment.meter.device_id}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} md={2}>
                                        <Box display={'flex'} flexDirection={'column'}>
                                            <Typography variant={'h6'} align={'center'}>
                                                Contractual Power
                                            </Typography>
                                            <Typography variant={'body1'} align={'center'}>
                                                {selectedAssignment.meter.contract_pw} kW
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} md={2}>
                                        <Box display={'flex'} flexDirection={'column'}>
                                            <Typography variant={'h6'} align={'center'}>
                                                Production
                                            </Typography>
                                            <Typography variant={'body1'} align={'center'}>
                                                {selectedAssignment.meter.prod_pw} kW
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} md={2}>
                                        <Box display={'flex'} flexDirection={'column'}>
                                            <Typography variant={'h6'} align={'center'}>
                                                Type
                                            </Typography>
                                            <Typography variant={'body1'} align={'center'}>
                                                {selectedAssignment.meter.type}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Container>

                        <Container maxWidth={false} sx={{ mt: 3, mb: 5 }} data-testid={'dailyTipMyDailyTip'}>
                            <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
                                <Grid container>
                                    <TipsAndUpdatesIcon
                                        sx={{ fontSize: '30px', marginRight: 1, color: theme.palette.primary.main }} />
                                    <Typography variant={'h5'} sx={{ color: theme.palette.primary.main, fontWeight: 500 }}>
                                        My daily tip
                                    </Typography>
                                </Grid>
                                <Grid container rowSpacing={1} spacing={1} mt={1}>
                                    {/* <Grid item xs={3} md={2}>
                            <Item>
                                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}
                                     alignItems={'center'}>
                                    <TipsAndUpdatesIcon sx={{fontSize: '70px'}}/>
                                </Box>
                            </Item>
                        </Grid> */}
                                    <Grid item xs={9} md={10}>
                                        <Typography variant="body1">
                                            {selectedAssignment.assigned_cluster_profile.recommendation.description}<br />
                                            {selectedAssignment.assigned_cluster_profile.recommendation.details}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Container>
                    </>
                    :
                    !loading &&
                    <div style={{ display: 'flex', alignItems: 'center', margin: 20 }}>
                        <ErrorOutlineOutlined sx={{ marginRight: '8px' }} />
                        <Typography variant="body1">Daily tip not available for this smart meter</Typography>
                    </div>}
            </>}
        </>
    );
}

export default DailyTip;